export const HOME = "/";
export const SERVICES = "services";
export const TEAM = "team-member";
export const LOCATION = "location";
export const CONTACT = "contact";
export const BLOG = "blog";

// same name for websites
export const DRBHARGAVSADAWATE = "DR. BHARGAV SADAWARTE";
export const DRPRIYANKASADAWATE = "DR. PRIYANKA JAIN-SADAWARTE";
