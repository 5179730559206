import React from "react";
import "./DoctorInfo.scss";
import { TeamMembers } from "./TeamMembers";

function TeamCard() {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="team_card_grid d-grid">
          {TeamMembers?.map((item) => {
            return (
              <div className="card">
                <div className="card__image">
                  <img src={item?.teamImg} alt="Team-Member" />
                </div>

                <div className="bg-light text-center p-4 pt-5">
                  <h4 className="card__name">{item?.name}</h4>
                  <p className="card__title">
                    {item?.proffessionalTitle}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TeamCard;
