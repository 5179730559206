import teamPerson1 from "./men_dummy.jpg";
import teamPerson2 from "./women_dummy.jpg";

export const TeamMembers = [
  {
    name: "Dr. Prajot Yevale",
    proffessionalTitle: "Dental",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Nitesh Patkar",
    proffessionalTitle: "Dental",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Anurag Singh",
    proffessionalTitle: "Dental",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Prassana Nara",
    proffessionalTitle: "Dental",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Mugdha Lawand",
    proffessionalTitle: "Dental",
    teamImg: teamPerson2,
  },
];
