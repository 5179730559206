import React from "react";
import TrustedByCard from "./TrustedByCard";
import style from "./TrustedBy.module.scss";

export default function TrustedByNew({
  trustedByTitle,
  className,
  isScroll,
  trustedByData,
}) {
  return (
    <div
      className={`${style["trusted-by-container"]} common-background-light-blue`}
    >
      <TrustedByCard
        className={className}
        isScroll={isScroll}
        trustedByData={trustedByData}
      />
    </div>
  );
}
