import React from "react";
import { Route, Routes } from "react-router";
import { HOME, SERVICES, TEAM, LOCATION, CONTACT, BLOG } from "./constant";
import FinalLandingPageSectionInOne from "../components/FinalComponents/FinalLandingPageSectionInOne";
import Team from "../components/FinalComponents/Team";
import Services from "../components/FinalComponents/Sevices";
import Location from "../components/landingPage/Location/Location";
import Contact from "../components/landingPage/Contact/Contact";
import Blogs from "../components/blogs/Blogs";
import BlogDetail from "../components/blogs/BlogDetails";

function AppRoute() {
  return (
    <Routes>
      <Route path={HOME} element={<FinalLandingPageSectionInOne />} />
      <Route path={SERVICES} element={<Services />} />
      <Route path={TEAM} element={<Team />} />
      <Route path={LOCATION} element={<Location />} />
      <Route path={BLOG} element={<Blogs />} />
      <Route path="/blog/:blogId" element={<BlogDetail />} />
      {/* <Route path={CONTACT} element={<Contact />} /> */}
    </Routes>
  );
}

export default AppRoute;
