import React from "react";
import logoImage from "../image/logo.png";
import "./LandingPage.scss";

function ImageWithLocation() {
  return (
    <div className="location-with-img-background">
      <div class="container">
        <div class="row align-items-center">
          <div className="d-flex justify-content-center">
            <img
              src={logoImage}
              alt="image-with-location"
              className="location-with-image-height"
            />
            {/* <video
            width="100%"
            height="auto"
            className="video-player position-relative"
            ref={videoRef}
            controls={isVideoPlaying}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video
            loop="loop"
            autoplay=""
            playsinline=""
            muted=""
            width="1920"
            height="1080"
            id="mejs_0752372885143835_html5"
            preload="none"
            src="https://atldentalspa.wpenginepowered.com/wp-content/uploads/2019/08/ads-hero-aug-19.mp4"
            style="margin: 0px; width: 1435px; height: 807.188px;"
          >
            <source
              type="video/mp4"
              src="https://atldentalspa.wpenginepowered.com/wp-content/uploads/2019/08/ads-hero-aug-19.mp4"
            />
          </video> */}
          </div>
          <div className="text_font_josefin text-center font-bold text-5xl mb-14">
            Your Caring Dentists in Badlapur, Maharashtra.
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageWithLocation;
