import galleryImg1 from "../../image/toothStoryImg/galleryImg1.jpg";
import galleryImg2 from "../../image/toothStoryImg/galleryImg2.jpg";
import galleryImg3 from "../../image/toothStoryImg/galleryImg3.jpg";
import galleryImg4 from "../../image/toothStoryImg/galleryImg4.jpg";
import galleryImg5 from "../../image/toothStoryImg/galleryImg5.jpg";
import galleryImg6 from "../../image/toothStoryImg/galleryImg6.jpg";
import galleryImg7 from "../../image/toothStoryImg/galleryImg7.jpg";
import galleryImg8 from "../../image/toothStoryImg/galleryImg8.jpg";
import galleryImg9 from "../../image/toothStoryImg/galleryImg9.jpg";

// import galleryImg1 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg"
// import galleryImg2 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg"
// import galleryImg3 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg"
// import galleryImg4 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
// import galleryImg5 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg"
// import galleryImg6 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg"
// import galleryImg7 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg"
// import galleryImg8 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg"
// import galleryImg9 from "https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg"

export const galleryData = [
  {
    galleryImg: galleryImg1,
  },
  {
    galleryImg: galleryImg2,
  },
  {
    galleryImg: galleryImg3,
  },
  {
    galleryImg: galleryImg4,
  },
  {
    galleryImg: galleryImg5,
  },
  {
    galleryImg: galleryImg6,
  },
  {
    galleryImg: galleryImg7,
  },
  {
    galleryImg: galleryImg8,
  },
  {
    galleryImg: galleryImg9,
  },
  {
    galleryImg: galleryImg4,
  },
  {
    galleryImg: galleryImg5,
  },
  {
    galleryImg: galleryImg6,
  },
];
