import svg1 from "../../image/svg/emergency.svg";
import svg2 from "../../image/svg/braces.svg";
import svg3 from "../../image/svg/teethCleaning.svg";
import svg4 from "../../image/svg/dentures.svg";
import svg5 from "../../image/svg/dentalImplants.svg";
import svg6 from "../../image/svg/dentalCheckUp.svg";

export const cardData = [
  {
    cardText: "Pediatric Dentist",
    cardBorderColor: "",
    icon: svg1,
    cardSVGBorderColor: "",
  },
  {
    cardText: "Braces",
    cardBorderColor: "",
    icon: svg2,
    cardSVGBorderColor: "",
  },
  {
    cardText: "Teeth Cleaning",
    cardBorderColor: "",
    icon: svg3,
    cardSVGBorderColor: "",
  },
  {
    cardText: "Dentures",
    cardBorderColor: "",
    icon: svg4,
    cardSVGBorderColor: "",
  },
  {
    cardText: "Dental Implants",
    cardBorderColor: "",
    icon: svg5,
    cardSVGBorderColor: "",
  },
  {
    cardText: "Dental Exams And Check-Ups",
    cardBorderColor: "",
    icon: svg6,
    cardSVGBorderColor: "",
  },
];
