import React from "react";
import Card from "../landingPage/CardsLayout/Card";
import BeforeAfterEffect from "../landingPage/BeforeAfterEffect/BeforeAfterEffect";
import ImageWithLocation from "../landingPage/ImageWithLocation";
import Testimonial from "../sections/Testimonial/Testimonial";
import LandingPage from "../landingPage/LandingPage";

const Services = () => {
  return (
    <div>
      <LandingPage />
      <Card />
      <BeforeAfterEffect />
      <Testimonial />
      <ImageWithLocation />
    </div>
  );
};

export default Services;
