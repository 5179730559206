import React, { useEffect, useState } from "react";
import image from "./IMG_20230914_141038-2e.jpg";
import "./DoctorInfo.scss";
import { Link } from "react-router-dom";

function RightSideImageWithDoctorInfo({
  name,
  degree,
  isButtonHide = false,
  buttonText,
}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to update the screen width when the window is resized
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to update the screen width on window resize
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Define a breakpoint for your responsive design
  const breakpoint = 768;
  return (
    <div className="team_member_bg_right">
      <div className="pt-20 pb-20">
        <div className="text_font_josefin text-center text-6xl">{name}</div>
        <div
          className="text_font_josefin text-3xl text-center"
          style={{ color: "#ff8c00" }}
        >
          {degree}
        </div>
        {screenWidth < breakpoint ? (
          <div class="row mx-auto position-relative w-4/5">
            <div class="col-12 col-md-8 mt-5">
              <div class="col-md-4 col-sm-12">
                <div class="d-flex justify-content-center">
                  <div class="img-border">
                    <img src={image} class="img-padding" alt="" />
                  </div>
                </div>
              </div>
              <div className="text_font_josefin text-2xl text-start mt-5">
                Illahee Dental Click to learn more about Illahee Dentalis a
                full-service dental practice in Bremerton, WA. We offer a wide
                range of options under one roof – general, cosmeticClick to
                learn more about cosmetic, and restorative – we do it all!
              </div>
              <div className="text_font_josefin text-2xl text-start mt-3">
                But before we even start looking at your teeth, we want to get
                to know you and help create a warm, welcoming dental
                environment. Here is what to expect when you visit:
              </div>
              <div className="text_font_josefin text-2xl text-start mt-3">
                From cleanings to dental implants to same-day tooth repairs,
                you’re in good hands at Illahee Dental.
              </div>
              {!isButtonHide && (
                <div className="d-flex justify-content-center mt-5">
                  <Link to={"/team-member"}>
                    <button class="glow-on-hover" type="button">
                      MEET THE TEAM
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div class="row mx-auto position-relative w-4/5 mt-5">
            <div class="col-12 col-md-8">
              <div className="text_font_josefin text-2xl text-start">
                Dr. Bhargav Sadawarte grew up in Badlapur, graduated from
                Kolhapur and did his post graduation in Pedodontics and
                Preventive Dentistry from Sangli. In addition to private
                practice and consultations in more than 10 clinics, Dr. Bhargav
                is lecturer at Nanded Rural Dental College. He is an active
                member of Indian Society of Pedodontics and Preventive Dentistry
                (ISPPD) Indian Dental Association (IDA) and serves as President
                of IDA Badlapur Branch (Year 2023) and has publications in
                National and International Journals. He chose to devote his
                career to pediatric dentistry because he is passionate about
                working with children and enjoys positively, influencing their
                dental experiences.
              </div>
              {!isButtonHide && (
                <div className="d-flex justify-content-center mt-5">
                  <Link to={"/team-member"}>
                    <button
                      className="bg-[#fb9220] text-white px-5 py-2 rounded-full hover:bg-[#e08b22]"
                      type="button"
                    >
                      MEET THE TEAM
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="d-flex justify-content-center">
                <div className="img-border">
                  <img
                    src={image}
                    className="img-padding"
                    alt=""
                    // style={{ height: "318px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RightSideImageWithDoctorInfo;
