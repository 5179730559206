import React from "react";
import image2 from "./priyanka-photo.jpg";
import "./DoctorInfo.scss";
import { Link } from "react-router-dom";

function LeftSideImageWithDoctorInfo({
  name,
  degree,
  isButtonHide = false,
  buttonText,
}) {
  return (
    <div className="team_member_bg_left">
      <div className="pt-20 pb-20">
        <div className="text_font_josefin text-center text-6xl">{name}</div>
        <div
          className="text_font_josefin text-3xl text-center"
          style={{ color: "#ff8c00" }}
        >
          {degree}
        </div>
        <div class="row mx-auto position-relative w-4/5 mt-5">
          <div class="col-md-4 col-sm-12">
            <div class="d-flex justify-content-center">
              <div class="img-border">
                <img src={image2} class="img-padding" alt="" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div className="text_font_josefin text-2xl text-start">
              Dr. Priyanka Jain-Sadawarte graduated from Belgaum and obtained
              MDS from Sangli and she was a gold medalist from the university.
              She was nominated for "Prosthodontist of the year" at Indian
              Dental Diva Awards 2019. She has publications in national and
              international journals. She is a member of Indian Dental
              Association (IDA), Indian Prosthodontic Society (IPS) and
              International Congress of Oral Implantology (ICOI). Family is very
              important to her, and it is always her goal to treat you, the
              patient, like family. She will strive to help make you feel
              comfortable and satisfied with each treatment you have at this
              clinic And we hope that in time, you feel like you are
              part of our family.
            </div>
            {/* <div className="text_font_josefin text-2xl text-start mt-3">
              But before we even start looking at your teeth, we want to get to
              know you and help create a warm, welcoming dental environment.
              Here is what to expect when you visit:
            </div>
            <div className="text_font_josefin text-2xl text-start mt-3">
              From cleanings to dental implants to same-day tooth repairs,
              you’re in good hands at Illahee Dental.
            </div> */}
            {!isButtonHide && (
              <div className="d-flex justify-content-center mt-5">
                <Link to={"/team-member"}>
                  <button
                    className="bg-[#fb9220] text-white px-5 py-2 rounded-full hover:bg-[#e08b22]"
                    type="button"
                  >
                    {buttonText}
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSideImageWithDoctorInfo;
