import React from "react";

function ClinicLocation() {
  return (
    <div className="px-14 mt-7 mb-7">
      <iframe
        title="hello"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.6481578620555!2d73.23356666387943!3d19.166872554092905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ed2ad673c80f%3A0x9ddcd5ad69c4d057!2sThe%20Tooth%20Story-%20Dental%20Clinic%20for%20Kids%20%26%20Adults%20and%20Implant%20Centre!5e0!3m2!1sen!2sin!4v1680454363260!5m2!1sen!2sin"
        className="rounded-l-3xl rounded-r-3xl w-100"
      />
    </div>
  );
}

export default ClinicLocation;
