import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./Blogs.scss";
import Spinner from "../common/Spinner";

const BlogDetail = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const docRef = doc(db, "blogs", blogId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBlog(docSnap.data());
        } else {
          setError("Blog not found");
        }
      } catch (error) {
        setError("Failed to fetch blog");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  if (loading) return <Spinner />;

  return (
    <div className="blog-detail">
      <button onClick={handleBack} className="back-button">
        &larr; Back
      </button>
      {blog && (
        <>
          <img
            src={
              blog.image
                ? blog.image
                : "https://th.bing.com/th/id/R.71dd52285ae55f507219b4d3c481847f?rik=744FPL0mfl%2fotA&riu=http%3a%2f%2fbadlapurdiary.com%2fwp-content%2fuploads%2f2023%2f03%2fthe-tooth-story-bd1.jpeg&ehk=iy7NE0vbJ9WNxubCrLMD9OoQJx1MwJkmO3n3xhbVxdQ%3d&risl=&pid=ImgRaw&r=0"
            }
            alt={blog.title}
            className="blog-image-detail"
          />
          <div className="blog-content">
            <span className="blog-category">{blog.author}</span>
            <h2 className="blog-title">{blog.title}</h2>
            <p className="blog-description">{blog.description}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default BlogDetail;
