import React from "react";
import "./Location.scss";
import Gallery from "../../sections/GalleryView/Gallery";
import ImageWithLocation from "../ImageWithLocation";
import ClinicLocation from "./ClinicLocation";
import GallerySection from "../GallerySection/GallerySection";

function Location() {
  return (
    <>
      <ImageWithLocation />
      <GallerySection />
      <ClinicLocation />
    </>
  );
}

export default Location;
