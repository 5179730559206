import React from "react";
import "./office.scss";

const OfficeTiming = () => {
  return (
    <div className="d-flex contentWrapper container-office-section">
      <div className="office-detail">
        <h1 className="font-bold heading-fonts dark-orange-text">
          Our Liberty office
        </h1>
        <div className="text-underline mt-3 mb-5"></div>
        <h5 className="address-line mb-4 light-orange-text">
          8650 NE Shoal Creek Valley Dr <br /> Kansas City, MO 64157
        </h5>

        <h4 className="light-orange-text lh-base">
          Jackson Family Dental is conveniently located right off of Route 152.
          Enjoy our spa-like <br /> atmosphere and family-friendly approach. We
          look forward to seeing you!
        </h4>
        <div>
          <img
            width="40"
            height="40"
            src="https://img.icons8.com/glyph-neue/64/FD7E14/phone.png"
            alt="phone"
          />
          <img
            width="40"
            height="40"
            src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/FD7E14/external-map-location-vitaliy-gorbachev-fill-vitaly-gorbachev-1.png"
            alt="external-map-location-vitaliy-gorbachev-fill-vitaly-gorbachev-1"
          />
          <img
            width="40"
            height="40"
            src="https://img.icons8.com/ios-filled/50/FD7E14/calling.png"
            alt="calling"
          />
        </div>
      </div>
      <div className="d-flex align-items-center section-hours">
        <div className="days-detail">
          <h1 className="font-bold heading-fonts dark-orange-text">Hours</h1>
          <div className="text-underline-hrs mt-3 mb-5"></div>
          <h5>Monday</h5>
          <h5>Tuesday</h5>
          <h5>Wednesday</h5>
          <h5>Thursday</h5>
        </div>
        <div className="col-6 top-margin">
          <h5>7AM - 5PM</h5>
          <h5>7AM - 5PM</h5>
          <h5>7AM - 5PM</h5>
          <h5>7AM - 5PM</h5>
        </div>
      </div>
    </div>
  );
};

export default OfficeTiming;
