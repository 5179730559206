import React from "react";
import "./LandingPage.scss";

function LandingPage() {
  return (
    <div>
      <div
        class="site-blocks-cover"
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <span class="sub-text" style={{ color: "#ff8c00" }}>
                Smile brighter,
              </span>
              <h1>
                <strong>Live happier</strong>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
