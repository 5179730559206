import React from "react";
import "./CardLayout.scss";
import { cardData } from "./CardList";
import CardLayout from "./CardLayout";

export default function Card() {
  return (
    <>
      <div className="mb-5 pb-5">
        <div className="text_font_josefin text-center text-6xl pt-5 pb-5">
          <span style={{ color: "#ff8c00" }}>How can</span> We Help?
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="card-grid d-grid">
            {cardData.map((val, id) => {
              return (
                <CardLayout key={id} icon={val.icon} cardText={val.cardText} />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
