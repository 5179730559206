import React from "react";
import { galleryData } from "./GalleryDataList";

export default function GallerySection() {
  return (
    <>
      <div className="text_font_josefin text-center text-6xl p-5 mt-5 pt-5">
        Our <span style={{ color: "#ff8c00" }}>Happy Place</span>
      </div>
      {/* <h1 className="text-center "> OUR HAPPY PLACE </h1> */}
      <div class="container grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="grid gap-4">
          {galleryData.slice(0, 3).map((val, index) => (
            <div key={index}>
              <img
                className="h-auto max-w-full rounded-lg"
                src={val.galleryImg}
                alt="galleryImg"
              />
            </div>
          ))}
        </div>
        <div className="grid gap-4">
          {galleryData.slice(3, 6).map((val, index) => (
            <div key={index}>
              <img
                className="h-auto max-w-full rounded-lg"
                src={val.galleryImg}
                alt="galleryImg"
              />
            </div>
          ))}
        </div>
        <div className="grid gap-4">
          {galleryData.slice(6, 9).map((val, index) => (
            <div key={index}>
              <img
                className="h-auto max-w-full rounded-lg"
                src={val.galleryImg}
                alt="galleryImg"
              />
            </div>
          ))}
        </div>
        <div className="grid gap-4">
          {galleryData.slice(9, 12).map((val, index) => (
            <div key={index}>
              <img
                className="h-auto max-w-full rounded-lg"
                src={val.galleryImg}
                alt="galleryImg"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
