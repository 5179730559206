import React from "react";
import "./CardLayout.scss";

export default function CardLayout({ cardText, icon }) {
  return (
    <div className="d-flex justify-content-center align-items-center text-center card-body bg-white position-relative text-align-center">
     <div> <img src={icon} alt="tooth-story" className="m-auto svg"/>
      <h2>{cardText}</h2></div>
    </div>
  );
}
