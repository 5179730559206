import React from "react";

function BookAppointmentBtn({ className = "" }) {
  return (
    <button
      class={`${className}`}
      type="button"
      onClick={() =>
        window.open(
          "https://calendar.clinicia.com/show2?key=0bbfd30c6d7efe2fff86061e79c010db&cc=07cb79",
          "_blank"
        )
      }
    >
      Book Appointment
    </button>
  );
}

export default BookAppointmentBtn;
